import { useForm } from 'react-hook-form';
import { Card, Stack, Typography, Box } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import { FormProvider, RHFUploadBox } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import { useReportDeath } from '#/api/successor';
import useAccountSwitch from '#/hooks/useAccountSwitch';

type Props = {
  open: boolean;
  onClose: () => void;
  reportUserName?: string;
  reportUserId?: string;
};

export default function ReportDeathModal({
  open,
  onClose,
  reportUserName,
  reportUserId,
}: Props) {
  const { translate } = useLocales();

  const { handleSwitchAccount: refetchAccount, currentAccount } =
    useAccountSwitch();

  const { mutateAsync: reportDeath, isLoading } = useReportDeath();

  const defaultValues = useMemo(
    () => ({
      requested_for: reportUserId || currentAccount?.id,
      death_report_doc: '',
      executor_certificate: '',
    }),
    [reportUserId, currentAccount?.id]
  );

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, setValue, reset, watch } = methods;

  const onSubmit = async (data: any) => {
    if (data?.death_report_doc === '' || data?.executor_certificate === '') {
      toast.error(String(translate('toast_notifications.error.deathReport')));
      return;
    }

    const formData = new FormData();
    formData.append('death_report_doc', data.death_report_doc);
    formData.append('executor_certificate', data.executor_certificate);
    formData.append('requested_for', data.requested_for);

    await reportDeath(formData)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.deathReport'))
        );
        refetchAccount(currentAccount?.id);
        reset(defaultValues);
        onClose();
      })
      .catch(() => {
        toast.error(String(translate('toast_notifications.error.deathReport')));
      });
  };

  const handleReportDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setValue('death_report_doc', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleCertificateDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setValue('executor_certificate', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (currentAccount?.id || reportUserId) {
      reset(defaultValues);
    }
  }, [currentAccount?.id, reportUserId]);

  useEffect(() => {
    if (!open) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [open]);

  const reportDeathInfo = watch('death_report_doc');
  const certificateInfo = watch('executor_certificate');

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Card
        sx={{
          paddingX: '1rem',
          paddingY: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Typography variant="subtitle1">
          {String(translate('guest.reportDeath'))}
        </Typography>
        <Typography variant="body2">
          {String(
            translate('guest.reportDeathDescription', {
              first_name:
                reportUserName ||
                `${currentAccount?.first_name} ${currentAccount?.last_name}`,
            })
          )}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Typography variant="body2">
              {String(translate('global.formLabels.deathReport'))}
            </Typography>
            <RHFUploadBox
              name="death_report_doc"
              sx={{
                width: '100%',
              }}
              onDrop={handleReportDrop}
            />
            {reportDeathInfo && (
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  bottom: 4,
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                }}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  reportDeathInfo.name
                }
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Typography variant="body2">
              {String(translate('global.formLabels.executorCertificate'))}
            </Typography>
            <RHFUploadBox
              name="executor_certificate"
              sx={{
                width: '100%',
              }}
              onDrop={handleCertificateDrop}
            />
            {certificateInfo && (
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  bottom: 4,
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                }}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  certificateInfo.name
                }
              </Typography>
            )}
          </Box>
          <Stack direction="row" justifyContent="flex-end" marginTop={1}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              size="small"
              disabled={isLoading}
            >
              <Typography variant="body2">
                {String(translate('global.formLabels.submit'))}
              </Typography>
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
    </DialogAnimate>
  );
}
